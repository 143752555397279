@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,100;9..144,200;9..144,300;9..144,400;9..144,500;9..144,700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

body, html {
  @apply w-full h-full text-primary font-sans;
}

h1 {
  @apply font-bold text-5xl mb-4;
  @apply md:text-8xl m-6;
  @apply lg:text-9xl m-6;
}

h2 {
  @apply font-semibold text-2xl mb-4;
  @apply md:text-4xl m-6;
}

button {
  @apply p-4 text-white bg-black uppercase font-semibold text-lg;
}

section {
  @apply flex flex-col items-center justify-center;
}

p {
  @apply leading-8 max-w-4xl mb-4;
}

a {
  @apply text-blue-500 underline
}

footer {
  @apply flex items-center justify-center bg-gray-200 p-4;
}

#root {
  @apply w-full h-full;
}

.cover {
  @apply w-full h-full bg-cover bg-no-repeat bg-top;
  background-image: url('./assets/cover.jpg');
}

.container {
  @apply w-full flex flex-col justify-center items-center m-4 p-4;
}

.cover-container {
  @apply w-full h-full flex flex-col justify-between items-center m-4 p-4;
}

.card-container {
  @apply flex flex-col justify-center items-center w-full;
}

.card {
  @apply bg-gray-50 rounded-lg flex flex-col drop-shadow-lg mb-6 mt-6 justify-center items-center;
  @apply xl:flex-row;
}

.imageSection {
  @apply w-80 h-80 bg-cover rounded-lg border-2 m-6;
}

.profilePic {
  @apply rounded-t-xl;
}


.textSection {
  @apply p-4 flex flex-col justify-center items-center;
}

.store {
  @apply w-full h-full absolute top-0 bg-white flex flex-col justify-between items-center;
}

.name {
  @apply font-bold text-3xl mb-4;
}